body {
  background-color: black;
  color: wheat
}

header {
  background-color: slategrey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  z-index: 1000;
  font-size: 12pt;
}

header .inner {
  display: flex;
  text-align: left;
  /* font-size: 100%; */
}

header .title {
  width: 70%;
}

header h1 {
  color: darkred;
  font-style: italic;
  font-size: 1.5em;
}

header p {
  color: black;
  font-size: 1em;
  animation-name: lgbtColors;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  font-size: 0.75em;
}

header img {
  height: 120px;
  padding: 10px;
  padding-bottom: 0;
}

header nav {
  margin: 25px;
  margin-right: 0px;
  display: flex;
}

header nav .el {
  background-color: darkslategrey;
  padding: 20px;
  margin: 1px;
}

header nav .el:hover {
  background-color: darkred;
}

header a{
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 1em;
}

.content {
  margin-top: 143px;
  margin-bottom: 70px;
}

.content .inner {
  padding: 5px
  
}

.pannel {
  background-color: darkslategray;
  height: 100%;
  margin: 1px;
}

.socialmedia a{
  font-size: xxx-large;
  text-decoration: none;
  color: whitesmoke
}

.socialmedia div{
  display: inline-block;
  padding: 15px;
}

.socialmedia a:hover{
  color: darkred;
}

.pictGallery .inner {
  padding-bottom: 40px;
}

.pictGallery .row {
  display: flex;
  flex-direction: row;
}

.pictGallery .row .left {
  width: 50%;
}

.pictGallery .row .right {
  width: 50%;
}
.pictGallery .row .right p {
  text-align: justify;
}

.pictGallery .row h3 {
  text-align: left;
}

.shelf {
  padding-bottom: 1px;
}

.shelf > .inner{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 5px;
  background-color: #203737;
}

.book {
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  font-size: 12px;
}
.book .cover {
  background-color: darkslategray;
  border: 1pt solid #fff;
  margin: 5px;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  height: 300px;
  overflow: hidden;
  width: 25px;
  cursor: pointer;
}

.book .cover.new {
  background-color: darkslateblue;
}

.book .cover.details {
  width: 200px;
  writing-mode: horizontal-tb;
  cursor: unset;
}

.book .info {
  font-size: 1em;
  margin-top: 30px;
}

.book .icon {
  font-size: 5em;
}
.book .icon a{
  text-decoration: none;
  color: #fff;
}

.book .icon a:hover{
  color: slategrey;
}

.book .text {
  height: 125px;
  border: 1px solid #fff;
  overflow: hidden;
  text-align: justify;
  background-color: darkolivegreen;
  color: #fff;
}

.book .text:hover {
  overflow-y: scroll;
}

@media only screen and (max-width: 600px){
  header img {
    height: 100px;
  }
  header h1 {
    font-size: 1.5em;
  }
  header p {
    font-size: .75em;
  }
}

@keyframes lgbtColors {
  0% {
    color: black;
  }
  12.5% {
    color: brown;
  }
  25% {
    color: red;
  }
  37.5% {
    color: orange;
  }
  50% {
    color: yellow;
  }
  62.5% {
    color: green;
  }
  75% {
    color: blue;
  }
  87.5% {
    color: purple;
  }

}
