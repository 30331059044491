footer.siteFooter {
    background-color: slategrey;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; 
    display: flex;
    justify-content: stretch;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1000;
}

.siteFooter img {
    height: 40px;
}

.siteFooter .inner  {
    padding: 5px;
    
} 

.siteFooter>div {
    width: 33.3%;
}

.siteFooter .feedback {
    background-color: darkslategray;
    border: 1px solid #fff;
    margin: auto;
    padding: 5px;
    font-size: 0.75em;
    height: 30px;
}
