.showcase .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.showcase .pic {
    background-color: slategrey;
    padding: 5px;
    margin: 5px;
}

.showcase .pic:hover {
    background-color: darkred;
}

.showcase .pic img {
    height: 200px;
}
